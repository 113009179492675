$(document).ready(function () {
    matchHeight();
    handleCarousel();
    gallery();
    handleTable();
    handleIframe();
    convertToTap();
    floatingFormLabels();
    customFileUpload();
    customSelect();
    handleSelect();
    searchBox();
    zoomControls();
});

$(window).on("load", function() {

    handleFooter();
    $(window).trigger('resize');
});

function matchHeight() {
    $('article .block-title').matchHeight();
}

function handleCarousel() {

    ////gallery carousel
    var owlGallery = $("#owl-gallery");
    owlGallery.owlCarousel({
        items: 1,
        loop: false,
        mouseDrag: false,
        touchDrag: true,
        slideSpeed: 800,
        paginationSpeed: 800,
        dots: false,
        animateOut: 'fadeOut',
        nav: true,
        navText: ['<span class="custom-arrow custom-arrow--dark custom-arrow--left"></span>','<span class="custom-arrow custom-arrow--dark custom-arrow--right"></span>'],
        autoplay: false
    });

    ////partners carousel
    var owlGallery = $("#owl-partners");
    owlGallery.owlCarousel({
        items: 2,
        loop: false,
        mouseDrag: false,
        touchDrag: true,
        slideSpeed: 800,
        paginationSpeed: 800,
        dots: false,
        stagePadding: 25,
        nav: true,
        navText: ['<span class="custom-arrow custom-arrow--dark custom-arrow--left"></span>','<span class="custom-arrow custom-arrow--dark custom-arrow--right"></span>'],
        autoplay: true,
        autoplayHoverPause: true
    });
}

function gallery() {

    $(".gallery").each(function(index,item) {

        $(this).magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile mfp-fade',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
                tCounter: '%curr% van %total%' // Markup for "1 of 7" counter
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title');
                }
            },
            removalDelay: 300
        });

        var galleryLength = $(item).find("a").length;

        if (galleryLength == 1) {
            $(item).addClass('one-image');
        } else if (galleryLength == 2) {
            $(item).addClass('two-image');
        } else if (galleryLength == 3) {
            $(item).addClass('three-image');
        } else if (galleryLength >= 4) {
            $(item).addClass('more');
        }
    });
}

window.onload = function() {
    progressiveImageLoader();
    progressiveBackgroundImage();
};

function progressiveBackgroundImage() {

    var placeholder = $(".bg-progressive");

    placeholder.each(function(index,element) {

        var imgUrlSmall = $(element).data('small');
        var imgUrlLarge = $(element).data('large');

        var imageWrapperSmall = $('<div class="background-image-small"></div>');
        var imageWrapperLarge = $('<div class="background-image-large"></div>');

        $(imageWrapperSmall).css("background-image","url(" + imgUrlSmall +")");
        $(imageWrapperLarge).css("background-image","url(" + imgUrlLarge +")");

        $(imageWrapperSmall).appendTo(element);
        $(imageWrapperLarge).appendTo(element);

        var img = new Image();
        img.src = imgUrlLarge;

        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };
    });
}

function progressiveImageLoader() {
    var placeholder = $("figure.figure-progressive");

    placeholder.each(function(index,element) {

        //load small image
        var imageWrapperSmall = $('<img class="image-small">');
        imageWrapperSmall.attr('src', $(element).attr('data-small'));

        // //load large image
        var imageWrapperLarge = $('<img class="image-large">');
        imageWrapperLarge.attr('src', $(element).attr('data-large')).attr('srcset', $(element).attr('data-srcset')).attr('alt', $(element).attr('data-alt'));;

        imageWrapperSmall.appendTo(element);
        imageWrapperLarge.appendTo(element);

        var img = new Image();
        img.src = $(element).attr('data-large');

        //create image in DOM
        img.onload = function () {
            $(imageWrapperSmall).addClass('loaded');
            $(imageWrapperLarge).addClass('loaded');
        };

    });
}

function handleFooter() {
    $(window).resize(function() {
        var contentHeight = Math.round($("body").innerHeight());
        var windowWidth= $(window).innerWidth();
        var windowHeight = window.innerHeight;

        if((windowWidth >= 768) && ( contentHeight <= windowHeight)){
            $("footer").addClass("sticky");
        } else {
            $("footer").removeClass("sticky");
        }
    });
}

function handleTable() {
    $('table').addClass('table table-striped');
}

function handleIframe() {
    $("iframe").each(function (index, element) {
        $(element).wrap( "<div class='responsive-iframe-container'></div>" );
    });
}

function convertToTap() {
    $(".tabs").each(function (index, element) {

        var tabsContainer = $(element).find(".tab-container");
        var tabsComponent = tabsContainer.find(".tab-component");
        var accordionElement = $('<div class="accordion" id="accordionComponent"></div>');

        $(accordionElement).appendTo(tabsContainer);

        var $newAccordionItemTitle = new Array();
        var $newAccordionItemText = new Array();
        //
        tabsComponent.find(".nav-tabs .nav-item").each(function (index, element) {
            $newAccordionItemTitle.push(element.text);
            $(accordionElement).append('<div class="card"><div class="card-header" id="heading'+ index +'" data-toggle="collapse" data-target="#collapse'+ index +'" aria-controls="collapse'+ index +'">' + $newAccordionItemTitle[index] +'<span class="custom-arrow"></span></div></div>');

        });

        tabsComponent.find(".tab-content .tab-pane").each(function (index, element) {
            $newAccordionItemText.push($(this).text());
            accordionElement.find('.card:nth-child(' + (index + 1) +')').append('<div class="collapse" id="collapse'+ index +'" data-parent="#accordionComponent"><div class="card-body">'+ $newAccordionItemText[index] +'</div></div>');
            accordionElement.find('.card:first-child .card-header').attr("aria-expanded","true");
            accordionElement.find('.card:first-child .collapse').addClass("show");
        });

        $(window).resize(function() {
            var screenSize = $( window ).outerWidth();

            if (screenSize < 768) {
                $(accordionElement).appendTo(tabsContainer);
                $(tabsComponent).detach();
            }
            else {
                $(tabsContainer).find(accordionElement).detach();
                $(tabsComponent).appendTo(tabsContainer);
            }
        });

    });
}

function floatingFormLabels() {
    $('.floating-label').each(function () {
        var inputField = $(this).find('input, textarea');

        if(inputField.val()){
            $(this).addClass('float');
        } else {
            $(this).removeClass('float');
        }

        inputField.on('focusin', function (index, element) {
            $(this).parent().addClass('float');
        });

        inputField.on('focusout', function (index, element) {
            if($(this).val() == ""){
                $(this).parent().removeClass('float');
            }
        });

    });
}

function customFileUpload() {
    $('input[type=file]').each(function (index, element) {
        var fileElement = $(element).parent();
        var fileNameDiv = '<div class="custom-upload-filename"></div>';
        $(fileNameDiv).appendTo(fileElement);

        $(element).on('change', function () {
            $(fileElement).find(".custom-upload-filename").empty();
            var filename = $(element).val().replace(/.*(\/|\\)/, '');
            fileElement.find(".custom-upload-filename").append(filename);
        });
    });
}

function customSelect() {

    $('select.custom-select').each(function(index, element){
        var $selectElem = $(element);
        var assignedRoleId = new Array();
        var items = new Array();

        $($selectElem).find('option').each(function(){
            assignedRoleId.push('<li role="option" id="" aria-selected="false" data-value="' + this.value + '">' + this.text + '</li>');
            items.push(this.text);
        });
        var customSelect = '<div class="md-select" id="'+$selectElem.attr('id')+'">\n' +
            '          <label class="select-button-label" for="'+$selectElem.attr('id')+'">\n' +
            '            <button type="button">' + items.slice(0,1) + '</button>\n' +
            '          </label>\n' +
            '          <ul role="listbox" id="'+$selectElem.attr('id')+'" name="'+$selectElem.attr('name')+'">' + assignedRoleId.join("\n") + '</ul>\n' +
            '        </div>';

        $(customSelect).insertBefore($selectElem);
    });
}

function handleSelect() {
    $('.md-select').each(function (index, element) {
        $(element).click('click', function (e) {
            e.preventDefault();
            $(element).toggleClass('active')

        });
        $(element).find('ul li').on('click', function () {
            //default list
            if($(this).find('input').length === 0) {
                $(element).find('ul li').attr('aria-selected', false);
                $(this).attr('aria-selected', true);

                //multiselect list
            } else {
                if($(this).attr('aria-selected') === 'false'){
                    $(this).attr('aria-selected', true);
                } else {
                    $(this).attr('aria-selected', false);
                }
            }

            var text = $(this).text();
            var value = $(this).attr('data-value');

            $(element).find('ul li').not($(this)).removeClass('active');
            $(this).removeClass('active');
            $(element).find('label button').text(text);

            $(element).next().val(value);
            $(element).next().trigger('change');
        })
    });
}

function searchBox() {

    $('.searchbox-wrapper .wrapper').click(function (e) {
        if(e.target.id != "form-search") {
            $(this).parents('.searchbox-wrapper').toggleClass('searchbox-wrapper--show');
        }
    });

    $('.searchbox-wrapper input.form-search').keyup(function() {
        if($(this).val() != "") {
            $(this).parents('.searchbox-wrapper').addClass('searchbox-wrapper--show-button');
        } else {
            $(this).parents('.searchbox-wrapper').removeClass('searchbox-wrapper--show-button');
        }
    });
}

function zoomControls() {
    var zoomlevel = 0;
    var zoomStep = 8;
    var zoomMin = 0;
    var zoomMax = 2;

    $('.zoom-controls #textIncrease').click(function () {
        if (zoomlevel < zoomMax) {
            zoomlevel++;
            updateZoom(zoomlevel);
        }
    });

    $('.zoom-controls #textDecrease').click(function () {
        if (zoomlevel > zoomMin) {
            zoomlevel--;
            updateZoom(zoomlevel);
        }
    });

    function updateZoom(zoom) {
        var test = zoom;
        console.log(test);
        $('body header .megamenu a, body header .breadcrumb').css('font-size', 100 + zoomStep * test + '%');
        $('body main').css('font-size', 100 + zoomStep * test + '%');

        //FF fix
        // $('body header .megamenu a').css('-moz-transform', 'scale('+ (1 + zoomStep * test * 0.5) + ')');
        // $('body main').css('-moz-transform', 'scale('+ (1 + zoomStep * test * 0.5) + ')');
    }
}
